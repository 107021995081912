import React from 'react'
import HeroGradient from 'components/hero-image/hero-image-gradient'
import { Helmet } from 'react-helmet'
import Floater from 'components/floating-grid'
import Layout from 'components/layout'
import Content from 'components/category/content'
import { graphql } from 'gatsby'

const items = [
  {
    link: '/implements/utility-tractors/front-end-loaders-for-tractors/loaders/',
    imageId: 'loadersImage',
    name: 'Loaders',
    alt: 'Loaders',
  },
]

const FrontEndLoadersPage = ({ data }) => {
  const { heroImage } = data
  const gridItems = items.map(item => {
    item.image = data[item.imageId]
    return item
  })
  return (
    <Layout>
      <Helmet>
        <title>Utility Tractor Implements - Front-End Loaders | Hutson Inc</title>
        <meta
          name='description'
          content='Find the right front-end loader for your John Deere utility tractor at Hutson. Find specs and features of loaders and see which tractors they’re compatible with.'
        />
        <script type='application/ld+json'>
          {JSON.stringify({
            '@context': 'http://schema.org',
            '@type': 'BreadcrumbList',
            'itemListElement': [
              {
                '@type': 'ListItem',
                'position': 1,
                'name': 'Hutson Inc',
                'item': 'https://www.hutsoninc.com/',
              },
              {
                '@type': 'ListItem',
                'position': 2,
                'name': 'Implements',
                'item': 'https://www.hutsoninc.com/implements/',
              },
              {
                '@type': 'ListItem',
                'position': 3,
                'name': 'Utility Tractors',
                'item': 'https://www.hutsoninc.com/implements/utility-tractors/',
              },
              {
                '@type': 'ListItem',
                'position': 4,
                'name': 'Front-End Loaders',
                'item':
                  'https://www.hutsoninc.com/implements/utility-tractors/front-end-loaders-for-tractors/',
              },
            ],
          })}
        </script>
      </Helmet>

      <HeroGradient image={heroImage} heading='Front-End Loaders' />

      <Content>
        <Floater items={gridItems} />
      </Content>
    </Layout>
  )
}

export const pageQuery = graphql`
  {
    heroImage: file(
      relativePath: {
        eq: "implements/utility-tractors/front-end-loaders-for-tractors/front-end-loaders-header.jpg"
      }
    ) {
      ...FullWidthImage
    }
    loadersImage: file(
      relativePath: { eq: "implements/utility-tractors/front-end-loaders-for-tractors/loaders.jpg" }
    ) {
      ...FloatingGridImage
    }
  }
`

export default FrontEndLoadersPage
